import React, { useState, useRef } from 'react';
import { HeroSection } from "./components/HeroSection";
import { ProfileCard } from "./components/ProfileCard";
import { FeaturesSection } from "./components/FeaturesSection";
import { PricingSection } from "./components/PricingSection";
import { ContactSection } from "./components/ContactSection";

interface Premium {
  kelas: string;
  harga: string;
}

export default function App() {
  const [premiums, setPremiums] = useState<Premium[]>([]);
  const [name, setName] = useState<string>('');
  const [age, setAge] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const profileCardRef = useRef<HTMLDivElement>(null);
  const featuresSectionRef = useRef<HTMLDivElement>(null);

  const scrollToProfileCard = () => {
    if (profileCardRef.current) {
      profileCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToFeaturesSection = () => {
    if (featuresSectionRef.current) {
      featuresSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <main className="min-h-screen bg-gray-50">
      <HeroSection setPremiums={setPremiums} setName={setName} setAge={setAge} setGender={setGender} scrollToProfileCard={scrollToProfileCard} />
      <div ref={profileCardRef}>
        <ProfileCard premiums={premiums} name={name} age={age} gender={gender} scrollToFeaturesSection={scrollToFeaturesSection} />
      </div>
      <div ref={featuresSectionRef}>
        <FeaturesSection />
      </div>
      <PricingSection premiums={premiums} />
      <ContactSection />
    </main>
  );
}