import { Button } from "./ui/button";

interface Premium {
  kelas: string;
  harga: string;
}

interface ProfileCardProps {
  premiums: Premium[];
  name: string;
  age: string;
  gender: string;
  scrollToFeaturesSection: () => void;
}

export function ProfileCard({ premiums, name, age, gender, scrollToFeaturesSection }: ProfileCardProps) {
  return (
    <div className="max-w-4xl mx-auto px-4 my-8">
      <div className="bg-teal-500 rounded-3xl p-8">
        <div className="flex flex-col md:flex-row items-start gap-8">
          <div className="flex-shrink-0">
            <div className="w-32 h-32 rounded-full bg-white p-2">
              <img
                src="/contact3.webp"
                alt=""
                className="w-full h-full object-contain"
              />
            </div>
          </div>
          <div className="flex-1">
            <h3 className="text-3xl font-semibold text-white mb-6">Hallo...</h3>
            <div className="grid grid-cols-[auto,1fr] gap-x-4 gap-y-2 text-white mb-4">
              <span>Nama :</span>
              <span>{name}</span>
              <span>Umur :</span>
              <span>{age} Tahun</span>
              <span>kelamin :</span>
              <span>{gender === 'male' ? 'Laki-laki' : 'Perempuan'}</span>
            </div>
            <p className="text-white text-lg">
              Berikut rekomendasi pilihan terbaik untukmu
            </p>
          </div>
          <div className="bg-teal-400/50 rounded-2xl p-6 min-w-[250px]">
            <div className="space-y-2 text-white mb-6">
              {Array.isArray(premiums) && premiums.map((premium, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span>{premium.kelas}</span>
                  <span className="font-semibold">
                    Rp {parseInt(premium.harga).toLocaleString('id-ID')}
                  </span>
                </div>
              ))}
            </div>
            <Button className="w-full bg-white text-teal-600 hover:bg-white/90" onClick={scrollToFeaturesSection}>
              Selengkapnya
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}