import { Button } from "./ui/button";

export function ContactSection() {
  return (
    <div className="bg-teal-500 py-8">
      <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row items-center justify-between gap-4">
        <h2 className="text-xl font-semibold text-white text-center sm:text-left">
          Butuh bantuan? Konsultasi sekarang
        </h2>
        <a href="https://wa.me/6285269411091" target="_blank" rel="noopener noreferrer">
          <Button className="bg-white text-teal-600 hover:bg-white/90">
            Contact Us
          </Button>
        </a>
      </div>
    </div>
  );
}