import { Check } from 'lucide-react'

export function FeaturesSection() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h2 className="text-2xl font-semibold text-center mb-8">
        Fasilitas Yang Akan Kamu Dapatkan
      </h2>
      <div className="grid md:grid-cols-2 gap-6">
        <Feature text="Kartu RS sesuai tagihan" />
        <Feature text="No claim bonus berupa saldo flexi benefit" />
        <Feature text="Limit Indonesia 10 M per tahun" />
        <Feature text="No deposit, tanpa rujukan" />
        <Feature text="Limit Asia 15 M per tahun" />
        <Feature text="Cover rawat inap & rawat jalan" />
      </div>
    </div>
  )
}

function Feature({ text }: { text: string }) {
  return (
    <div className="flex items-center gap-3">
      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-teal-500 flex items-center justify-center">
        <Check className="w-4 h-4 text-white" />
      </div>
      <span className="text-gray-700">{text}</span>
    </div>
  )
}

