import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button } from "./ui/button";

interface Premium {
  kelas: string;
  harga: string;
}

interface PricingSectionProps {
  premiums: Premium[];
}

export function PricingSection({ premiums }: PricingSectionProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openModal = (image: string) => {
    setModalImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImage('');
  };

  const kelas1 = premiums.find(premium => premium.kelas === 'Kelas 1');
  const vip = premiums.find(premium => premium.kelas === 'VIP');

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <div className="grid md:grid-cols-2 gap-8">
        <div className="border-2 border-teal-500 rounded-3xl overflow-hidden">
          <div className="bg-teal-500 py-4 px-6">
            <h3 className="text-2xl font-semibold text-white text-center">
              Kelas 1
            </h3>
          </div>
          <div className="p-6 space-y-6">
            <img
              src="/kelas1.webp"
              alt="Kamar Kelas 1"
              className="w-full h-48 object-cover rounded-xl cursor-pointer"
              onClick={() => openModal('/kelas1.webp')}
            />
            <a href="https://wa.me/6285269411091" target="_blank" rel="noopener noreferrer">
              <Button 
                className="w-full bg-teal-50 text-teal-500 hover:bg-teal-100 rounded-full font-semibold py-3 px-6 mt-4"
              >
                Pilih Paket
              </Button>
            </a>
          </div>
        </div>

        <div className="border-2 border-teal-500 rounded-3xl overflow-hidden">
          <div className="bg-teal-500 py-4 px-6">
            <h3 className="text-2xl font-semibold text-white text-center">
              VIP
            </h3>
          </div>
          <div className="p-6 space-y-6">
            <img
              src="/VIP.webp"
              alt="Kamar VIP"
              className="w-full h-48 object-cover rounded-xl cursor-pointer"
              onClick={() => openModal('/VIP.webp')}
            />
            <a href="https://wa.me/6285269411091" target="_blank" rel="noopener noreferrer">
              <Button 
                className="w-full bg-teal-50 text-teal-500 hover:bg-teal-100 rounded-full font-semibold py-3 px-6 mt-4"
              >
                Pilih Paket
              </Button>
            </a>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
      >
        <div className="relative">
          <button onClick={closeModal} className="absolute top-0 right-0 m-2 text-white text-2xl">&times;</button>
          <img src={modalImage} alt="Modal" className="max-w-full max-h-full" />
        </div>
      </Modal>
    </div>
  );
}