import React, { useState } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Select } from "./ui/select";

interface Premium {
  kelas: string;
  harga: string;
}

interface InsuranceFormProps {
  setPremiums: React.Dispatch<React.SetStateAction<Premium[]>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setAge: React.Dispatch<React.SetStateAction<string>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  scrollToProfileCard: () => void;
}

export function InsuranceForm({ setPremiums, setName, setAge, setGender, scrollToProfileCard }: InsuranceFormProps) {
  const [localName, setLocalName] = useState<string>('');
  const [localAge, setLocalAge] = useState<string>('');
  const [localGender, setLocalGender] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!localName || !localAge || !localGender) {
      setError('Semua kolom wajib diisi.');
      return;
    }
    setError('');
    setName(localName);
    setAge(localAge);
    setGender(localGender);
    const response = await fetch('https://dash.cekpremiallianz.com/api/get-premium', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ age: localAge, gender: localGender }),
    });
    const data = await response.json();
    setPremiums(data);
    scrollToProfileCard();
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-2xl shadow-lg overflow-hidden">
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-4">Cek Premi Anda</h2>
        <div className="flex gap-4 items-start">
          <img
            src="/contact2.webp"
            alt="Nurse Avatar"
            className="w-1/3 h-auto"
          />
          <form className="flex-1 space-y-4" onSubmit={handleSubmit}>
            {error && <p className="text-red-500">{error}</p>}
            <Input 
              placeholder="Nama" 
              className="w-full bg-gray-50 border-gray-200"
              value={localName}
              onChange={(e) => setLocalName(e.target.value)}
            />
            <Input 
              type="number" 
              placeholder="Usia" 
              className="w-full bg-gray-50 border-gray-200"
              value={localAge}
              onChange={(e) => setLocalAge(e.target.value)}
            />
            <Select 
              className="w-full bg-gray-50 border-gray-200"
              value={localGender}
              onChange={(e) => setLocalGender(e.target.value)}
            >
              <option value="">Jenis Kelamin</option>
              <option value="male">Laki-laki</option>
              <option value="female">Perempuan</option>
            </Select>
            <Button className="w-full bg-teal-500 hover:bg-teal-600 text-white">
              Cek Sekarang
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}