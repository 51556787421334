import React, { useRef } from 'react';
import { Button } from "./ui/button";
import { InsuranceForm } from "./InsuranceForm";

interface Premium {
  kelas: string;
  harga: string;
}

interface HeroSectionProps {
  setPremiums: React.Dispatch<React.SetStateAction<Premium[]>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setAge: React.Dispatch<React.SetStateAction<string>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  scrollToProfileCard: () => void;
}

export function HeroSection({ setPremiums, setName, setAge, setGender, scrollToProfileCard }: HeroSectionProps) {
  const formRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative min-h-[600px] flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: "url('/herocover.webp')" }}>
        <div className="absolute inset-0 bg-teal-600/80" />
      </div>
      <div className="relative max-w-6xl mx-auto w-full px-4 py-16 sm:py-24 lg:py-32">
        <div className="text-center mb-32">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white mb-6 leading-tight">
            Cari Tahu Rekomendasi Asuransi Kesehatan Terbaik Untukmu
          </h1>
          <p className="text-white/90 text-lg mb-8 max-w-2xl mx-auto">
            Dapatkan perlindungan kesehatan terbaik untuk Anda dan keluarga dengan rekomendasi yang sesuai kebutuhan
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <Button size="lg" className="bg-white text-teal-600 hover:bg-white/90" onClick={scrollToForm}>
              Mulai Sekarang
            </Button>
            <Button size="lg" variant="outline" className="text-white border-white hover:bg-white/10" onClick={scrollToForm}>
              Pelajari Lebih Lanjut
            </Button>
          </div>
        </div>
        <div ref={formRef}>
          <InsuranceForm setPremiums={setPremiums} setName={setName} setAge={setAge} setGender={setGender} scrollToProfileCard={scrollToProfileCard} />
        </div>
      </div>
    </div>
  );
}